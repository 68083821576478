// extracted by mini-css-extract-plugin
export var articleLast = "librederm-module--articleLast--7635a";
export var articleTextBox = "librederm-module--articleTextBox--b0b37";
export var block__info = "librederm-module--block__info--6863a";
export var block__info__img = "librederm-module--block__info__img--60ffd";
export var block__info__title = "librederm-module--block__info__title--e45a5";
export var container = "librederm-module--container--1dd49";
export var container__img = "librederm-module--container__img--7c171";
export var content = "librederm-module--content--914fd";
export var contentContainer = "librederm-module--contentContainer--9a390";
export var decor = "librederm-module--decor--d4a2b";
export var decorLeft = "librederm-module--decorLeft--8deeb";
export var decorRight = "librederm-module--decorRight--9d199";
export var divider = "librederm-module--divider--c4b81";
export var firstText = "librederm-module--firstText--8ec54";
export var mobile__img = "librederm-module--mobile__img--259de";
export var productImage = "librederm-module--productImage--e94aa";
export var sectionTitle__img = "librederm-module--sectionTitle__img--a5f94";
export var sectionTitle__title = "librederm-module--sectionTitle__title--2dce8";
export var section__img = "librederm-module--section__img--b31c3";
export var statCard = "librederm-module--statCard--db9fb";
export var statCardsContainer = "librederm-module--statCardsContainer--50f36";
export var statDescr = "librederm-module--statDescr--977bd";
export var statValue = "librederm-module--statValue--79473";
export var statisticsWrapper = "librederm-module--statisticsWrapper--416b4";
export var text = "librederm-module--text--aa872";
export var textBlock = "librederm-module--textBlock--92a62";
export var textBlockGreen = "librederm-module--textBlockGreen--47c08";
export var textBlockWhite = "librederm-module--textBlockWhite--8ea10";
export var title = "librederm-module--title--6c34c";
export var wrapper = "librederm-module--wrapper--690a8";