import React, { useRef, useEffect, useState } from "react"
import cx from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/free-mode"
import { Link } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { getImgSrc } from "../../helpers/images"
import useMediaQuery from "../../hooks/useMediaQuery"

import * as styles from "./Expertise.module.css"
import nutrilonImage from "../../assets/images/nutrilon/nutrilonCase.svg"
import LacalutImage from "../../assets/images/lacalut/Lacalutcase.png"
import LibredermImage from "../../assets/images/librederm/libredermCard.png"

const Expertise = ({ showMore = false, fromCase = false }) => {
  const { t } = useTranslation()
  const { allWpCase } = useStaticQuery(
    graphql`
      query {
        allWpCase(
          filter: { status: { eq: "publish" } }
          sort: { order: ASC, fields: menuOrder }
        ) {
          nodes {
            title
            excerpt
            link
            featuredImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const isMobile = useMediaQuery("(max-width: 1023px)")
  const swiperRef = useRef(null)
  const [visibleItems, setVisibleItems] = useState(6)

  const initialLacalutCase = {
    title: "Lacalut",
    excerpt: "Expert case study for Lacalut.",
    link: "/cases/lacalut/",
    featuredImage: {
      node: {
        altText: "Lacalut",
        localFile: {
          publicURL: LacalutImage,
          childImageSharp: null,
        },
      },
    },
  }

  const initialLibredermCase = {
    title: "Librederm",
    excerpt: "Expert case study for Librederm.",
    link: "/cases/librederm/",
    featuredImage: {
      node: {
        altText: "Librederm",
        localFile: {
          publicURL: LibredermImage,
          childImageSharp: null,
        },
      },
    },
  }

  const initialNutrilonCase = {
    title: "Nutrilon",
    excerpt: "Expert case study for Nutrilon.",
    link: "/case",
    featuredImage: {
      node: {
        altText: "Nutrilon",
        localFile: {
          publicURL: nutrilonImage,
          childImageSharp: null,
        },
      },
    },
  }

  // eslint-disable-next-line
  const [caseList, setCaseList] = useState([
    initialLibredermCase,
    initialLacalutCase,
    initialNutrilonCase,
    ...allWpCase.nodes,
  ])

  useEffect(() => {
    if (!swiperRef.current) return

    if (!isMobile) {
      swiperRef.current?.disable()
    } else {
      swiperRef.current?.enable()
    }
  }, [isMobile, swiperRef])

  const handleShowMore = () => {
    setVisibleItems(caseList.length)
  }

  const handleCaseClick = caseItem => {
    if (caseItem.title === "Nutrilon") {
      navigate("/cases/nutrilon/")
    } else {
      const newLink = caseItem.link.replace("/case/", "/cases/")
      navigate(newLink)
    }
  }

  return (
    <section className={cx(styles.wrapper, "container")}>
      <div className={!showMore ? styles.container : styles.showMoreSection}>
        <h2 className={styles.sectionTitle}>{t("Наша экспертиза")}</h2>
        {!fromCase ? (
          <div className={styles.casesSlider}>
            <Swiper
              onSwiper={swiper => (swiperRef.current = swiper)}
              slidesPerView="auto"
              spaceBetween={24}
              breakpoints={{
                1023: {
                  spaceBetween: 0,
                },
              }}
            >
              {caseList.slice(0, visibleItems).map((item, index) => (
                <SwiperSlide key={index} className={styles.slide}>
                  <div
                    aria-hidden="true"
                    onClick={() => handleCaseClick(item)}
                    className={styles.caseLink}
                    role="button"
                    tabIndex={0}
                  >
                    <div className={styles.caseImage}>
                      <img
                        className={styles.images}
                        src={getImgSrc(item).src}
                        alt={getImgSrc(item).alt}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <div
            className={cx(styles.boxFromCases, {
              [styles.flexWrapOnMobile]: fromCase && isMobile,
            })}
          >
            {caseList.slice(0, visibleItems).map((item, index) => (
              <div key={index} className={styles.boxFromCasesItem}>
                <div
                  aria-hidden="true"
                  onClick={() => handleCaseClick(item)}
                  className={styles.caseLink}
                  role="button"
                  tabIndex={0}
                >
                  <div className={styles.caseImage}>
                    <img
                      className={styles.images}
                      src={getImgSrc(item).src}
                      alt={getImgSrc(item).alt}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {showMore && visibleItems < caseList.length && (
          <div className={styles.showMoreBtnBox}>
            <button onClick={handleShowMore} className={styles.showMoreBtn}>
              {t("Ещё кейсы")}
            </button>
          </div>
        )}
      </div>
      {!showMore && (
        <div className={styles.moreWrapper}>
          <Link to="/cases/" className="theme-button-text">
            {t("Далее")}
          </Link>
        </div>
      )}
    </section>
  )
}

export default Expertise
