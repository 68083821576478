import React from "react"
import Layout from "../../components/layout"
import Form from "../../components/Form"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as styles from "./librederm.module.css"

import LibredermImage from "../../assets/images/librederm/LibredermImage.jpg"
import LibredermImage2 from "../../assets/images/librederm/ImageLibrederm2.jpg"
import Buyers from "../../assets/images/librederm/buyers.svg"
import Buyer from "../../assets/images/librederm/buyer.svg"
import Couple from "../../assets/images/librederm/couple.svg"
import decorLeftImage from "../../assets/images/librederm/decoreLeft.svg"
import decorRightImage from "../../assets/images/librederm/decoreRight.svg"
import tubeImage from "../../assets/images/librederm/tubeImage.svg"

const Librederm = ({ pageContext }) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className={styles.wrapper}>
        <section>
          <div className={styles.container}>
            <Breadcrumbs pageContext={pageContext} title="Librederm" />
            <h2 className={`${styles.title} ${styles.sectionTitle__title}`}>
              Увеличили продажи на 24% с помощью performance-рекламы
            </h2>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.sectionTitle__img} `}
            src={LibredermImage}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.sectionTitle__img}
              src={LibredermImage}
              alt="LibredermImage"
            />
            <p className={`${styles.text} ${styles.firstText}`}>
              Привлечь и удержать аудиторию конкурентов только лишь обычными
              инструментами маркетплейсов — нелегко. Для усиления трафика
              на маркетплейсах и увеличения доли новой аудитории команда бренда
              LIBREDERM обратилась в DVGROUP. Нам предстояло найти новый сегмент
              покупателей и переключить аудиторию брендов-конкурентов на линейку
              SERACIN LIBREDERM, при этом добиться ROAS выше 2,48.
            </p>
            <p className={styles.text}>
              Перед запуском внешнего трафика по локальным сегментам мы всегда
              проверяем количество товара, доступное для заказа. А также
              оптимизируем карточки товаров на маркетплейсах: наполняем
              их контентом, смотрим на количество отзывов и качество визуала.
            </p>
            <p className={styles.text}>
              Следующим этапом мы приступили к сборке локальных сегментов
              через свою CDP-платформу. Проанализировали онлайн-транзакции
              пользователей из более 50 онлайн-магазинов, мобильных приложений
              и телекома. Выделили три ключевых сегмента:
            </p>
            <div className={styles.block__info}>
              <div>
                <div className={styles.container__img}>
                  <img
                    className={styles.block__info__img}
                    src={Buyers}
                    alt="Покупатели брендов"
                  />
                </div>

                <h3 className={styles.block__info__title}>
                  Покупатели в категории за последние полгода
                </h3>
              </div>
              <div>
                <div className={styles.container__img}>
                  <img
                    className={styles.block__info__img}
                    src={Buyer}
                    alt="Покупатели конкурентов "
                  />
                </div>

                <h3 className={styles.block__info__title}>
                  Покупатели брендов конкурентов за последние полгода
                </h3>
              </div>
              <div>
                <div className={styles.container__img}>
                  <img
                    className={styles.block__info__img}
                    src={Couple}
                    alt="Look-a-like"
                  />
                </div>

                <h3 className={styles.block__info__title}>
                  LAL на аудиторную подборку покупателей бренда Librederm
                </h3>
              </div>
            </div>
            <p className={styles.text}>
              Выбрали сплит каналов VK реклама и Yandex РСЯ для лучшего
              результата. и запустили performance-рекламу.
            </p>
            <p className={styles.text}>
              Транслировали в креативах два товара вместе — очищающий гель
              для умывания и крем-гель. Выделили на продукты скидку до 30%.
              Так покупатель сразу понимал, что это полноценный двухступенчатый
              уход и купить набором выходит выгоднее. Объявления из двух
              продуктов показали хороший результат, CTR = 1,96%.
            </p>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.section__img} `}
            src={LibredermImage2}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.section__img}
              src={LibredermImage2}
              alt="LibredermImage"
            />
          </div>
        </section>
        <article>
          <div className={styles.divider}></div>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <img
                src={decorLeftImage}
                alt="Decor left"
                className={`${styles.decor} ${styles.decorLeft}`}
              />
              <div className={`${styles.textBlock} ${styles.textBlockGreen}`}>
                <p>
                  Учитывали особенности экономики бренда: CPO для нового клиента
                  могла быть выше в 3 раза из-за длинного LT
                </p>
              </div>
              <div className={`${styles.textBlock} ${styles.textBlockWhite}`}>
                <p>
                  Наблюдали за динамикой и назначали разную предельную ставку в
                  зависимости от ценности клиента
                </p>
              </div>
              <img
                src={decorRightImage}
                alt="Decor right"
                className={`${styles.decor} ${styles.decorRight}`}
              />
            </div>
          </div>
          <div className={styles.divider}></div>
        </article>
        <article className={styles.articleTextBox}>
          <div className={styles.container}>
            <p className={styles.text}>
              CDP даёт нам возможность не только собрать сегменты, но и оценить
              прибыль, которую приносит один клиент, проанализировать путь
              покупателя, а также выяснить, как и где он осуществил покупку.
              Кроме того, мы можем наблюдать за продажами после завершения
              рекламной кампании, если пользователь оказался в нашем сегменте.
            </p>
            <p className={styles.text}>
              Рекламная кампания показала положительный результат, мы увеличили
              продажи на 24% линейки SERACIN и перевыполнили ROAS. А также
              за время рекламной кампании увеличилась доля сопутствующих
              товаров.
            </p>
          </div>
        </article>
        <article>
          <div className={styles.divider}></div>
          <div className={styles.statisticsWrapper}>
            <img
              src={tubeImage}
              alt="tubeImage"
              className={styles.productImage}
            />
            <div className={styles.container}>
              <div className={styles.statCardsContainer}>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>19%</span>
                  <p className={styles.statDescr}>
                    перевыполнение плана по ROAS
                  </p>
                </div>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>+24%</span>
                  <p className={styles.statDescr}>
                    продажи линейки Серацин по сравнению с 2022 годом
                  </p>
                </div>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>2,95</span>
                  <p className={styles.statDescr}>средний ROAS</p>
                </div>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>+36%</span>
                  <p className={styles.statDescr}>рост выручки</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider}></div>
        </article>
        <article className={styles.articleLast}>
          <div className={styles.container}>
            <p className={styles.text}>
              При запуске внешнего трафика по локальным сегментам важно
              не забывать и про внутренние инструменты. Комплексный подход,
              сочетающий внешнюю и внутреннюю стратегию, даёт наилучший
              результат.
            </p>
          </div>
        </article>
      </div>
      <Form t={t} />
    </Layout>
  )
}

export default Librederm
