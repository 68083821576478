// extracted by mini-css-extract-plugin
export var TagileAwards = "lacalut-module--TagileAwards--abf5f";
export var active = "lacalut-module--active--d08e9";
export var arrow_left = "lacalut-module--arrow_left--339a9";
export var arrow_right = "lacalut-module--arrow_right--3ba36";
export var block__info = "lacalut-module--block__info--7f4f6";
export var block__info__img = "lacalut-module--block__info__img--78524";
export var block__info__left = "lacalut-module--block__info__left--4dcea";
export var block__info__left__list = "lacalut-module--block__info__left__list--2272e";
export var block__info__list = "lacalut-module--block__info__list--cbf50";
export var block__info__right = "lacalut-module--block__info__right--72d9e";
export var block__info__text = "lacalut-module--block__info__text--3b9c4";
export var block__info__title = "lacalut-module--block__info__title--85ce1";
export var card = "lacalut-module--card--7d7e0";
export var card__text = "lacalut-module--card__text--81071";
export var container = "lacalut-module--container--4b5aa";
export var custom_pagination = "lacalut-module--custom_pagination--f4987";
export var divider = "lacalut-module--divider--c5465";
export var image__container = "lacalut-module--image__container--575d0";
export var link__TagileAwards = "lacalut-module--link__TagileAwards--0e7d2";
export var navigation_swiper = "lacalut-module--navigation_swiper--8b407";
export var pagination_dot = "lacalut-module--pagination_dot--a4a04";
export var profileBlock = "lacalut-module--profileBlock--a68c9";
export var profileBlock__descr = "lacalut-module--profileBlock__descr--89326";
export var profileBlock__img = "lacalut-module--profileBlock__img--df1f1";
export var profileBlock__left = "lacalut-module--profileBlock__left--119fe";
export var sectionAdvertising = "lacalut-module--sectionAdvertising--5d73f";
export var sectionAdvertising__img = "lacalut-module--sectionAdvertising__img--93bdb";
export var sectionAdvertising__img_414 = "lacalut-module--sectionAdvertising__img_414--16321";
export var sectionAdvertising__swiper = "lacalut-module--sectionAdvertising__swiper--b874e";
export var sectionAdvertising__text = "lacalut-module--sectionAdvertising__text--9bebf";
export var sectionGrowth__img = "lacalut-module--sectionGrowth__img--14218";
export var sectionGrowth__img_414 = "lacalut-module--sectionGrowth__img_414--45c37";
export var sectionGrowth__list = "lacalut-module--sectionGrowth__list--988fa";
export var sectionGrowth__title = "lacalut-module--sectionGrowth__title--b30a8";
export var sectionTitle__descr = "lacalut-module--sectionTitle__descr--f14ff";
export var sectionTitle__img = "lacalut-module--sectionTitle__img--da31f";
export var sectionTitle__title = "lacalut-module--sectionTitle__title--a1eae";
export var section__Recommendations__list = "lacalut-module--section__Recommendations__list--ad5c3";
export var section__Recommendations__text = "lacalut-module--section__Recommendations__text--9803e";
export var section_contacts = "lacalut-module--section_contacts--c5fff";
export var slide__block = "lacalut-module--slide__block--8577f";
export var slide__img = "lacalut-module--slide__img--0aca2";
export var slide__tag = "lacalut-module--slide__tag--63c77";
export var text = "lacalut-module--text--68186";
export var text_margin = "lacalut-module--text_margin--b4c41";
export var title = "lacalut-module--title--10e89";
export var underline = "lacalut-module--underline--a4bf9";
export var wrapper = "lacalut-module--wrapper--3a2e9";