import React, { useState } from "react"
import Layout from "../../components/layout"
import Form from "../../components/Form"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"

import TagileAwardsIcon from "../../assets/images/TagileAwards.svg"
import LacalutImage from "../../assets/images/lacalut/LacalutImage.jpg"
import Brush1 from "../../assets/images/lacalut/toothbrush.png"
import Brush2 from "../../assets/images/lacalut/toothbrush2.png"
import Placeholder from "../../assets/images/lacalut/placeholder.png"
import OzonImage from "../../assets/images/lacalut/OzonImage.png"
import Slide1 from "../../assets/images/lacalut/slide1.png"
import Slide2 from "../../assets/images/lacalut/slide2.png"
import Slide3 from "../../assets/images/lacalut/slide3.png"
import Slide4 from "../../assets/images/lacalut/slide4.png"
import Slide5 from "../../assets/images/lacalut/slide5.png"
import Slide6 from "../../assets/images/lacalut/slide6.png"
import ArrowLeft from "../../assets/images/lacalut/arrow-left.svg"
import ArrowRight from "../../assets/images/lacalut/arrow-right.svg"
import WebCase from "../../assets/images/lacalut/web-case.png"
import WebCase_414 from "../../assets/images/lacalut/web-case_414.png"
import Photo from "../../assets/images/lacalut/Photo.png"
import * as styles from "./lacalut.module.css"

const Lacalut = ({ pageContext }) => {
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handleSlideChange = swiperInstance => {
    setIsBeginning(swiperInstance.isBeginning)
    setIsEnd(swiperInstance.isEnd)
  }

  const slides = [Slide1, Slide2, Slide3, Slide4, Slide5]
  return (
    <Layout>
      <div className={styles.wrapper}>
        <section>
          <div className={styles.container}>
            <Breadcrumbs pageContext={pageContext} title="Lacalut" />
            <h2 className={`${styles.title} ${styles.sectionTitle__title}`}>
              Увеличили продажи в 2 раза для LACALUT на Ozon и Wildberries с
              помощью внешнего трафика.
            </h2>
            <div className={styles.image__container}>
              <img
                className={styles.sectionTitle__img}
                src={LacalutImage}
                alt="LaclutImage"
              />
              <a
                className={styles.link__TagileAwards}
                href="https://tagline.ru/awards/winners/?utm_source=medal&utm_medium=banner&utm_campaign=none&utm_term=awards-winners"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.TagileAwards}
                  src={TagileAwardsIcon}
                  alt="TagileAwards"
                />
              </a>
            </div>
            <p className={styles.sectionTitle__descr}>
              C каждым годом покупатели все чаще ищут товары сразу
              на маркетплейсах, минуя офлайн и интернет-магазины. Такой спрос
              на площадки вызывает интерес и у продавцов. Только за 2023 год их
              количество (работающих на двух маркетплейсах) выросло на 64%
              по данным{" "}
              <a
                href="https://acdn.tinkoff.ru/static/documents/tinkoff-ecommerce-research-sellers-marketplaces-russia-2023.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                T-Bank eCommerce.
              </a>
            </p>
            <p className={styles.text}>
              Из-за этого становится сложнее бороться за внимание покупателя,
              в дополнение к внутренним инструментам продвижения важно
              подключать ещё и внешние. Они помогают переключить внимание
              покупателя с брендов-конкурентов и привести новую для бренда
              и маркетплейса аудиторию.
            </p>
            <p className={styles.text}>
              С такой задачей в начале 2024 года обратился к нам{" "}
              <a
                href="https://lacalut.ru/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                LACALUT®
              </a>{" "}
              — немецкий стоматологический бренд по уходу за полостью
              рта со столетней историей. Вместе с брендом мы работаем
              с 2023 года, помогаем увеличивать трафик и продажи
              на маркетплейсах.
            </p>
            <p className={styles.text}>
              В этот раз нам нужно было привлечь новую аудиторию и увеличить
              продажи сразу пяти линеек зубных паст LACALUT®: kids, junior,
              white, sensitive и aktiv. В качестве KPI выделили рост показателя
              рентабельности инвестиций в рекламу, нашей целью было достичь
              и удержать ROAS больше 3 (стартовали с отметки 2,5).
            </p>
            <p className={styles.text}>
              Мы в DV GROUP помогаем брендам увеличивать продажи
              на маркетплейсах, приводя качественный внешний трафик. Вместо
              стандартных интересов мы используем локальные сегменты на основе
              онлайн-транзакций покупателей для настройки таргетированной
              рекламы. В этом нам помогает собственная CDP-платформа.
              Она позволяет нам обрабатывать анонимизированные данные
              о покупателях, умеет распознавать магазин, бренд и категорию
              покупки.
            </p>
          </div>
        </section>
        <div className={styles.divider}></div>
        <section>
          <div className={styles.container}>
            <h2 className={styles.title}>
              Подготовка к внешнему трафику на маркетплейсах
            </h2>
            <p className={styles.text}>
              Для начала мы вместе с командой бренда проверили, все ли готово
              перед запуском внешнего трафика на Ozon и Wildberries, так как эти
              две площадки являлись ключевыми для LACALUT®. Проанализировали
              важные параметры:
            </p>
            <div className={styles.block__info}>
              <div className={styles.block__info__left}>
                <ol className={styles.block__info__left__list}>
                  <li>
                    Объём промо-позиций на складе и возможность повторной
                    отгрузки, чтобы товар был всегда в наличии
                  </li>
                  <li>
                    Конкурентное ценовое предложение. Проанализировали
                    при помощи CDP-платформы прошлые промо активности
                    конкурентов на маркетплейсах.{" "}
                  </li>
                  <li>
                    Карточки товаров. Проверили и обновили контент, описания
                    товаров.
                  </li>
                  <li>
                    Отзывы. При недостатке отзывов на определённые товары
                    рекомендовали команде бренда мотивировать покупателей
                    с помощью системы начисления баллов за каждый оставленный
                    отзыв.
                  </li>
                </ol>
              </div>
              <div className={styles.block__info__right}>
                <div className={styles.card}>
                  <p className={styles.block__info__text}>👍</p>
                  <p className={`${styles.text} ${styles.card__text}`}>
                    Все перечисленные параметры влияют на удержание высоких
                    позиций в выдаче и помогают конвертировать трафик в покупку.
                  </p>
                </div>
              </div>
            </div>
            <p className={styles.text}>
              После проведённого анализа в качестве оффера вместе с LACALUT®
              выделили скидку до 40% на все линейки зубных паст. А также
              придумали концепцию для креативов и подготовили их к старту
              рекламной кампании.
            </p>
            <p className={styles.text}>
              Дальше приступили к самому важному — сборке сегментов на основе
              онлайн-транзакций пользователей. Собрали данные из более
              50 онлайн-магазинов и мобильных приложений, а также телекома.
              Выделили три ключевых:
            </p>
            <div className={styles.block__info}>
              <div>
                <img
                  className={styles.block__info__img}
                  src={Brush1}
                  alt="Категории"
                />
                <h3 className={styles.block__info__title}>
                  Пользователей, которые ранее покупали в категориях
                </h3>
                <ul className={styles.block__info__list}>
                  <li>детские товары, для мам и малышей</li>
                  <li>зубные пасты на маркетплейсах</li>
                  <li>средства для дёсен</li>
                  <li>средства для отбеливания зубов</li>
                  <li>средства для чувствительных зубов</li>
                </ul>
              </div>
              <div>
                <img
                  className={styles.block__info__img}
                  src={Brush2}
                  alt="Конкуренты"
                />
                <h3 className={styles.block__info__title}>
                  Пользователей, которые ранее покупали у конкурентов.
                </h3>
              </div>
              <div>
                <img
                  className={styles.block__info__img}
                  src={Placeholder}
                  alt="Look-a-like"
                />
                <h3 className={styles.block__info__title}>
                  Look-a-like покупателей LACALUT®
                </h3>
              </div>
            </div>
            <p className={styles.text}>
              Для каждой линейки использовали дополнительные сегменты: для kids,
              junior — те, кто покупал товары для детей в целом и у конкурентов,
              для sensetive — те, кто покупал средства для чувствительных зубов
              в целом и у конкурентов и так далее.
            </p>
          </div>
        </section>
        <div className={styles.divider}></div>
        <section className={styles.sectionAdvertising}>
          <div className={styles.container}>
            <h2 className={styles.title}>
              Запуск рекламы на подборки Ozon и Wildberries
            </h2>
            <img
              className={styles.sectionAdvertising__img}
              src={OzonImage}
              alt="OzonImage"
            />
            <p className={styles.text}>
              Загрузили выделенные сегменты в рекламные кабинеты и запустили
              кампании в ЕПК Яндекс (единой перфоманс кампании), которая
              сочетает в себе РСЯ и Поиск. А также подключили рекламу в VK ADS.
              Такой сплит инструментов позволяет добиться быстрого результата.
              Весь трафик вели не на карточки товара, а на подборки.
              Это помогало нам снизить риск переключения внимания покупателя
              на конкурента, так как в подборке был широкий ассортимент бренда.
              Для работы выбрали формат текстово-графических объявлений.
            </p>
            <p className={styles.text}>
              Каждые две недели отслеживали динамику переходов и продаж.
              Если видели, что баннеры не работают или «выгорают», то обновляли
              их стилистику и УТП.
            </p>
            <img
              className={styles.sectionAdvertising__img_414}
              src={OzonImage}
              alt="OzonImage"
            />
            <p className={styles.text}>
              Не просто заменяли баннеры на новые, а анализировали, какие
              ассоциации могут появляться у покупателей при мысли о белоснежной
              улыбке или чувствительности зубов. Подбирали продукты и эмоции,
              которые вызывают нужную ассоциацию для того, чтобы покупатель
              мог легко считать наши баннеры. В процессе работы отказались
              от обезличенных креативов — лайфстайл креативы работают лучше.
            </p>
            <p className={`${styles.text} ${styles.sectionAdvertising__text}`}>
              Внешний трафик за месяц помог увеличить заказы выделенного
              ассортимента на 28%, оставаясь при этом в рамках ДРР.
            </p>
            <p className={styles.text}>
              Кроме ключевых сегментов, мы тестировали ещё и косвенные, на базе
              сопутствующих покупок товара. Например, для детской линейки брали
              сегмент покупателей сладостей для детей. А также собирали данные
              о пользователях на основе покупок в категориях: электрические
              щётки, ирригаторы, капы, уход за брекетами. Но такие сегменты
              отрабатывали не так эффективно, как стандартные.
            </p>
            <div className={styles.sectionAdvertising__swiper}>
              <Swiper
                onSwiper={setSwiper}
                onSlideChange={swiper => {
                  setActiveIndex(swiper.activeIndex)
                  handleSlideChange(swiper)
                }}
                spaceBetween={20}
                modules={[Navigation, Pagination]}
                breakpoints={{
                  1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  990: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  820: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  414: {
                    slidesPerView: 1.5,
                    spaceBetween: 60,
                  },
                  390: {
                    slidesPerView: 1.5,
                    spaceBetween: 70,
                  },
                  375: {
                    slidesPerView: 1.5,
                    spaceBetween: 90,
                  },
                  360: {
                    slidesPerView: 1.5,
                    spaceBetween: 90,
                  },
                }}
              >
                <SwiperSlide>
                  <div className={styles.slide__block}>
                    <span className={styles.slide__tag}>CTR 1,3%</span>
                    <img
                      className={styles.slide__img}
                      src={Slide1}
                      alt="Slide1"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.slide__block}>
                    <span className={styles.slide__tag}>CTR 1%</span>
                    <img
                      className={styles.slide__img}
                      src={Slide2}
                      alt="Slide2"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.slide__block}>
                    <span className={styles.slide__tag}>CTR 1,3%</span>
                    <img
                      className={styles.slide__img}
                      src={Slide3}
                      alt="Slide3"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.slide__block}>
                    <span className={styles.slide__tag}>CTR 0,6%</span>
                    <img
                      className={styles.slide__img}
                      src={Slide4}
                      alt="Slide4"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.slide__block}>
                    <span className={styles.slide__tag}>CTR 0,8%</span>
                    <img
                      className={styles.slide__img}
                      src={Slide5}
                      alt="Slide5"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.slide__block}>
                    <span className={styles.slide__tag}>CTR 0,7%</span>
                    <img
                      className={styles.slide__img}
                      src={Slide6}
                      alt="Slide6"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.navigation_swiper}>
                <button
                  onClick={() => swiper.slidePrev()}
                  disabled={isBeginning}
                >
                  <img
                    src={ArrowLeft}
                    className={styles.arrow_left}
                    alt="ArrowLeft"
                  />
                </button>
                <div className={styles.custom_pagination}>
                  {slides.map((_, index) => (
                    <button
                      key={index}
                      aria-label="Pagination menu"
                      onClick={() => swiper?.slideTo(index)}
                      className={`${styles.pagination_dot} ${
                        activeIndex === index ? styles.active : ""
                      }`}
                    ></button>
                  ))}
                </div>
                <button onClick={() => swiper.slideNext()} disabled={isEnd}>
                  <img
                    src={ArrowRight}
                    className={styles.arrow_right}
                    alt="ArrowRight"
                  />
                </button>
              </div>
            </div>
            <p className={styles.text}>
              Держали руку на пульсе и отслеживали не только продажи бренда,
              но и брендов-конкурентов. Сейчас продавцам доступна только
              статистика продаж бренда в целом и внутри категории. А мы помогаем
              заглянуть глубже и посмотреть аналитику в разрезе каждой категории
              и сделать конкурентный анализ.
            </p>
            <p className={styles.text}>
              CDP позволяет нам оценить сколько прибыли приносит один клиент,
              посмотреть путь покупателя, как и где он совершил покупку.
              А ещё можем увидеть продажи после завершения рекламной кампании,
              если пользователь попал в наш сегмент.
            </p>
          </div>
        </section>
        <section>
          <div className={styles.container}>
            <h2 className={styles.title}>
              Ozon и Wildberries: как увеличить продажи на 219%
            </h2>
            <img
              className={styles.sectionGrowth__img}
              src={WebCase}
              alt="WebCase"
            />
            <img
              className={styles.sectionGrowth__img_414}
              src={WebCase_414}
              alt="WebCase"
            />
            <p className={`${styles.text} ${styles.text_margin}`}>
              Благодаря подобранным сегментам для запуска внешнего трафика
              на маркетплейсы и сквозной аналитике, нам удалось увеличить
              количество продаж в 2 раза. Кроме этого, мы не просто остались
              в рамках ДРР, а перевыполнили KPI и увеличили отдачу
              от рекламы(ROAS) c 2,6 до 3,14.
            </p>
            <h3 className={styles.sectionGrowth__title}>
              Рост заказов линеек зубных паст LACALUT® с февраля по октябрь
            </h3>
            <ul className={styles.sectionGrowth__list}>
              <li>Линейка kids — рост продаж на 219%</li>
              <li>Линейка junior — рост продаж на 128%</li>
              <li>Линейка sensitive — рост продаж на 92%</li>
              <li>Линейка white — рост продаж на 67%</li>
              <li>Линейка aktiv — рост продаж на 52%</li>
            </ul>
          </div>
        </section>
        <div className={styles.divider}></div>
        <article>
          <div className={styles.container}>
            <div className={styles.profileBlock}>
              <div className={styles.profileBlock__left}>
                <img
                  className={styles.profileBlock__img}
                  src={Photo}
                  alt="Profile"
                />
                <span className={styles.profileBlock__descr}>
                  Александр Орлик
                </span>
                <p className={styles.text}>Директор по маркетингу LACALUT®</p>
              </div>
              <div>
                <p className={styles.text}>
                  Нам надо было усилить внутреннее продвижение на маркетплейсах.
                  Поэтому в DV GROUP мы обратились за внешним трафиком
                  и абсолютно новой аудиторией.
                </p>
                <p className={styles.text}>
                  Нам нравилась прозрачность в отчётах и анализе эффективности
                  кампании. Мы могли отслеживать результаты и видеть, какие
                  методы действительно работают. А ещё мы получили много
                  положительных отзывов от новых клиентов, это не может
                  не радовать! Определённо рекомендую сотрудничество с этой
                  командой тем, кто ищет реальных результатов!
                </p>
              </div>
            </div>
          </div>
        </article>
        <div className={styles.divider}></div>
        <section>
          <div className={styles.container}>
            <h2 className={styles.title}>Рекомендации</h2>
            <p className={styles.text}>
              Вместе с запуском внешнего трафика мы рекомендуем использовать
              ещё и внутренние инструменты продвижения на маркетплейсах:
            </p>
            <ul className={styles.section__Recommendations__list}>
              <li>отрабатывать отзывы</li>
              <li>
                обновлять rich-контент: описание, изображение, преимущества
                товара
              </li>
              <li>отслеживать цены на другие позиции</li>
              <li>
                следить за выдачей и использовать внутренние рекламные форматы
              </li>
            </ul>
            <p className={styles.text}>
              Для усиления эффекта от внешнего трафика рекомендуем запускать
              параллельно медийные размещения (ТВ, реклама у блогеров).
              Performance должен быть частью стратегии на постоянной основе.
            </p>
            <p className={styles.text}>
              Кроме того, для перформанс-размещений лучше использовать
              максимально глубокие скидки — от 20 до 40%. Это усилит ваше
              предложение и сделает его заметнее.
            </p>
            <p
              className={`${styles.text} ${styles.section__Recommendations__text}`}
            >
              Внешний трафик в совокупности с грамотной внутренней стратегией
              на маркетплейсах помогает увеличивать продажи, делать бренд
              заметнее, улучшать места в выдаче даже, если вы запускаете рекламу
              на новую аудиторию.
            </p>
          </div>
        </section>
      </div>
      <Form t={t} />
    </Layout>
  )
}

export default Lacalut
